<template>
  <div class="free-public">
    <Row :gutter="15">
      <Col :key="index" span="12" v-for="(item, index) in typeList">
        <Button :type="activeShow == index ? 'primary' : 'default'" @click="changeCategory(index, item)" style="width: 100%">{{ item }}</Button>
      </Col>
    </Row>
    <div style="padding: 20px 0 10px" >
      <Input v-if="activeShow === 0" :placeholder="`请添加您想${activeShow === 0 ? '采购' : '供应'}的产品`" style="width: 100%" v-model="productName" />
      <Select class="select_wrap" v-model="productName" v-else placeholder="请选择您需要供应的产品" >
        <Option v-for="item in proList" :value="item.name" :key="item.name">{{ item.name }}</Option>
      </Select>
    </div>
    <div style="padding: 10px 0">
      <Input :placeholder="`请详细描述您需要${activeShow === 0 ? '采购' : '供应'}的产品`" :rows="4" style="width: 100%" type="textarea" v-model="content" />
    </div>
    <div>
      <uploadNeed  :fileList="uploadBanner" @upload-ok="bannerOK" />
    </div>
    <Button @click="submit" long style="margin-top: 21px" type="primary">发布供需</Button>
  </div>
</template>

<script>
import uploadNeed from "@components/upLoad/upLoadNeed";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "pub-need",
  data() {
    return {
      typeList: [
        "采购产品",
        "供应产品",
        // "征求服务"
      ],
      activeShow: 0,
      selectTab: "采购产品",
      uploadBanner: [], // 活动图
      content: "",
      productName: "",
      proList:[],
      config: {
        fileType: "img",
        headers: {},
        action: "",
        format: ["jpg", "jpeg", "png"],
        "max-size": 1024 * 100,
        remind: "(图片要求：2M以下的jpg或png，建议尺寸为900*900px，最多上传4张)",
        limitNum: 4,
        id:'upload_1'
      },
    };
  },
  components: { uploadNeed },
  computed: {
    ...mapGetters({
      uploadUrl: "getUploadUrl",
      formHeaders: "getFormHeaders",
      exhibitionId: "getExhibitionId",
      exhibitionInfo: "getExhibitionInfo",
      userInfo: "getUser",
      login: "getLogin",
    }),
    isRegOk() {
      return this.userInfo && this.userInfo.inMember && this.userInfo.inMember.userRole && this.userInfo.inMember.userRole != "VISITOR" && this.userInfo.inMember.userRole != "Visitor";
    },
    userRole() {
      return this.userInfo && this.userInfo.inMember && this.userInfo.inMember.userRole ? this.userInfo.inMember.userRole : "";
    },
  },
  created() {
    if(this.userRole == "EXHIBITOR"){
      this.getProduct();
    }
  },
  methods: {
    ...mapActions({
      supplyDemandSubmit: "news/supplyDemand",
      unverifiedDialog: "unverifiedDialog",
      graphqlPost: "graphqlPostByZXS",
    }),
    bannerOK(list) {
      this.uploadBanner = list;
    },
    async getProduct(){
      if(this.userRole == "EXHIBITOR"){
      let query = `
        query{
          exhibitorQuery{
            get(id:"${this.userInfo.inMember.exhibitorId}"){
                products(num:10000){
                  name
                  id
                  nameEn 
                }
              }
            }
        }
      `;
      let opt = {
        query: query,
        variables: {},
      };
      let res = await this.graphqlPost(opt);
      console.log(res, "exinfo res");
      this.proList=res.data.exhibitorQuery.get.products
      }
    },
    reLoad() {
      this.activeShow = 0;
      this.selectTab = "采购产品";
      this.content = "";
      this.productName = "";
      this.uploadBanner = [];
    },
    async submit() {
      if (!this.login || !this.isRegOk) {
        this.unverifiedDialog(this);
        return;
      }
      if(this.userInfo&&this.userInfo.inMember.isBlacklist){
        this.$Message.warning('您已被屏蔽');
        return 
      }
      if(!this.getRoleHandle('L1Demand-L2List-L3List')){
        return 
      }
      if (!this.content) {
        this.$Message.error("请输入供需内容");
        return;
      }
      if (!this.productName) {
        if(this.activeShow==0){
          this.$Message.warning("请添加您想采购的采购产品");
        }else{
          this.$Message.warning("请选择您想采购的采购产品");
        }
        return;
      }
      this.content=this.content.split("\n").join("<br>");
      let opt = {
        memberId: this.userInfo.id,
        content: this.content,
        productName: this.productName,
        publisherType: 0,
        originName: this.exhibitionInfo.name || "平台",
        exhibitionId: this.exhibitionId,
        type: this.selectTab || "采购产品",
      };
      if (this.uploadBanner.length) {
        if(this.uploadBanner[0].cover){
          opt.videos=`[{"cover":"${this.uploadBanner[0].cover}","url":"${this.uploadBanner[0].url}","duration":"","coverIndex": 5}]`
        }else{
          let arr=[]
          this.uploadBanner.forEach(e=>{
            arr.push(e.url);
          })
          opt.imgUrls = arr.join(";");
        }
      }
      let data = await this.supplyDemandSubmit(opt);
      if (data && data.result) {
        this.$Message.success("提交成功");
        this.$emit("finish");
        this.reLoad();
      } else {
        this.$Message.error(data.message);
      }
    },
    changeCategory(index, item) {
      if (this.typeList[index] === "供应产品" && this.userRole !== "EXHIBITOR") {
        this.$Message.warning("仅展商可发布供应信息!");
        return;
      }
      this.productName='';
      this.activeShow = index;
      this.selectTab = item;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@assets/scss/my-theme/mixin.scss";
.free-public {
  padding: 20px;
}
.select_wrap{
  &::v-deep .ivu-select-dropdown{
    width: 268px;
  }
}
</style>
