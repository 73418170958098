import { render, staticRenderFns } from "./pub-need.vue?vue&type=template&id=65c69276&scoped=true&"
import script from "./pub-need.vue?vue&type=script&lang=js&"
export * from "./pub-need.vue?vue&type=script&lang=js&"
import style0 from "./pub-need.vue?vue&type=style&index=0&id=65c69276&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65c69276",
  null
  
)

export default component.exports